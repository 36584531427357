.dm-edit-video {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	
	video {
		max-width: 100%;
		max-height: 100%;
	}
}

.dm-edit-video--empty {
	border: 1px solid darkgrey;
}

.dm-edit__react-player {
  position: absolute;
  top: 0;
  left: 0;
}
