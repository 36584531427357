body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('https://static.directmail.io/dm-editor/fonts/MuseoSans-500.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('https://static.directmail.io/dm-editor/fonts/MuseoSans-500Italic.otf') format('opentype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('https://static.directmail.io/dm-editor/fonts/MuseoSans-700.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('https://static.directmail.io/dm-editor/fonts/MuseoSans-700Italic.otf') format('opentype');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'CeraPro';
  src: url('https://static.directmail.io/dm-editor/fonts/CeraProRegular.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'CeraPro';
  src: url('https://static.directmail.io/dm-editor/fonts/CeraProRegularItalic.otf') format('opentype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'CeraPro';
  src: url('https://static.directmail.io/dm-editor/fonts/CeraProBold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'CeraPro';
  src: url('https://static.directmail.io/dm-editor/fonts/CeraProBoldItalic.otf') format('opentype');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('https://static.directmail.io/dm-editor/fonts/Arial Narrow.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'MinionPro';
  src: url('https://static.directmail.io/dm-editor/fonts/MinionPro-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('https://static.directmail.io/dm-editor/fonts/RobotoCondensed-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('https://static.directmail.io/dm-editor/fonts/RobotoCondensed-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'TTSlu';
  src: url('https://static.directmail.io/dm-editor/fonts/TTSlu.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'TTSlu';
  src: url('https://static.directmail.io/dm-editor/fonts/TTSluBol.otf') format('opentype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Whyte';
  src: url('https://static.directmail.io/dm-editor/fonts/Whyte-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Whyte';
  src: url('https://static.directmail.io/dm-editor/fonts/Whyte-Italic.otf') format('opentype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Whyte';
  src: url('https://static.directmail.io/dm-editor/fonts/Whyte-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
}


@font-face {
  font-family: 'Whyte';
  src: url('https://static.directmail.io/dm-editor/fonts/Whyte-BoldItalic.otf') format('opentype');
  font-style: italic;
  font-weight: 700;
}
