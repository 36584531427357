.dm-edit-form__group {
	display: flex;
}

.dm-edit-form-optin {
  &.MuiFormControlLabel-root {
    margin: 0 0 0 -11px;
    align-items: flex-start;
  }

  .MuiCheckbox-root {
    margin-top: -9px;
  }
}
