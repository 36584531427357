.dm-edit-section {
	width: 100%;
	min-height: 90px;
	box-sizing: border-box;
  position: relative;
	height: fit-content;
}

.dm-edit-section--columns .dm-edit-section__content{
	display: flex;
}

.dm-edit-section__content {
	margin: 0 auto;
	width: 100%;
  max-width: 1200px;
	display: grid;
	grid-template-columns: repeat(36, 1fr);
  grid-auto-rows: 15px;
}
