.dm-edit-landing {
	min-height: 100vh;
	position: relative;
}

.dm-edit-landing--mobile {
	.dm-edit-section--columns .dm-edit-section__content{
		flex-direction: column;
	}

	.dm-edit-section--grid .dm-edit-section__content,
	.dm-edit-section-column {
		grid-auto-rows: 8px;
	}

	.dm-edit-section-column__grid {
		background-size: 11px 8px;
	}
}
